import {
  SiFacebook,
  SiInstagram,
  SiLinkedin,
  SiX,
  SiYoutube,
} from "@icons-pack/react-simple-icons";

export const socialNetworks = [
  {
    href: "https://www.facebook.com/CarrierAssure",
    icon: SiFacebook as React.FC<React.ComponentPropsWithRef<"svg">>,
    label: "Facebook",
  },
  {
    href: "https://twitter.com/carrierassure",
    icon: SiX as React.FC<React.ComponentPropsWithRef<"svg">>,
    label: "Twitter",
  },
  {
    href: "https://www.linkedin.com/company/carrier-assure/",
    icon: SiLinkedin as React.FC<React.ComponentPropsWithRef<"svg">>,
    label: "Linkedin",
  },
  {
    href: "https://www.instagram.com/carrierassure/",
    icon: SiInstagram as React.FC<React.ComponentPropsWithRef<"svg">>,
    label: "Instagram",
  },
  {
    href: "https://www.youtube.com/channel/UCb_3l2z4zPsvZeSS-CQDUXA",
    icon: SiYoutube as React.FC<React.ComponentPropsWithRef<"svg">>,
    label: "Youtube",
  },
];
